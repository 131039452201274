<script lang="ts" setup>
const year = new Date().getFullYear()
</script>

<template>
  <div class="flex flex-col items-center gap-4">
    <div class="-ml-6 flex w-32 lg:-ml-8 lg:w-44">
      <img class="w-full" src="/logo_full.svg" />
    </div>
    <span class="-ml-2 text-sm font-light text-stone-400">&copy; {{ year }}</span>
  </div>
</template>
