<script lang="ts" setup>
const nav = {
  left: [
    { label: 'Start', href: '/' },
    {
      label: 'Atelierluft',
      children: [
        { label: 'Angebote', href: '/' },
        { label: 'Regionen', href: '/regionen' },
        { label: 'Kunstszene', href: '/kunstszene' },
        { label: 'Künstler:innen', href: '/kuenstler-innen' },
      ],
    },
  ],
  right: [
    // { label: 'Über uns', href: '/' },
    {
      label: 'Hilfe',
      children: [
        { label: 'Datenschutzerklärung', href: '/datenschutz' },
        { label: 'AGB', href: '/agb' },
        { label: 'Nutzungsbedingungen', href: '/nutzungsbedingungen' },
        { label: 'Impressum', href: '/impressum' },
      ],
    },
  ],
}
</script>

<template>
  <AlPadding class="sticky top-[100vh] pb-4">
    <footer class="mx-auto flex max-w-4xl flex-col gap-12">
      <AlDecorativeImage src="/svg/footer-squiggly.svg" />
      <div
        class="flex flex-col justify-center gap-8 text-xl md:flex-row md:gap-14 md:text-lg lg:gap-32 lg:text-xl xl:gap-44"
      >
        <AlFooterNav
          :links="nav.left.flatMap((n) => n.children ?? n)"
          class="max-md:hidden md:items-end"
        />
        <div class="flex flex-col items-center gap-8">
          <AlFooterLogo />

          <div
            class="flex w-full items-center justify-center gap-6 text-stone-500 *:flex hover:*:text-al-primary-600"
          >
            <a href="https://www.facebook.com/profile.php?id=61550589274358" target="_blank">
              <UIcon name="i-ph-facebook-logo" class="h-8 w-8" />
            </a>
            <a href="https://www.instagram.com/atelierluft.de" target="_blank">
              <UIcon name="i-ph-instagram-logo" class="h-8 w-8" />
            </a>
          </div>
        </div>
        <AlFooterNav
          :links="nav.right.flatMap((n) => n.children ?? n)"
          class="max-md:hidden md:items-start"
        />
        <AlFooterNav :links="Object.values(nav).flat()" class="items-center md:hidden" />
      </div>
      <div class="border-t">
        <p class="my-6 text-center text-xl font-light text-stone-400">Gefördert von</p>
        <div
          class="flex flex-col items-center justify-center gap-x-12 gap-y-8 saturate-0 sm:px-12 md:flex-row"
        >
          <img src="/footer/logo_sachsen_geht_weiter.webp" class="h-16" />
          <img src="/footer/logo_eu.webp" class="max-h-12 w-full object-contain md:w-fit" />
        </div>
      </div>
    </footer>
  </AlPadding>
</template>
